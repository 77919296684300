<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <h3>اطلاعات درخواست</h3>
      </v-card-title>
      <v-tabs class="" fixed-tabs background-color="transparent" v-model="tab">
        <v-tab class="tab-title"> اطلاعات عمومی </v-tab>
        <v-tab class="tab-title"> تاریخچه درمانی </v-tab>
        <v-tab class="tab-title"> جزییات درخواست </v-tab>
        <v-tab class="tab-title"> مدیریت درخواست </v-tab>
        <v-tab class="tab-title" v-if="role == 'admin' && isDentistry == false">
          تغییر شیفت درخواست
        </v-tab>
      </v-tabs>

      <v-card class="br-card pa-3">
        <v-card-text>
          <div>
            <v-card>
              <v-tabs-items v-model="tab">
                <!-- اطلاعات عمومی -->
                <v-tab-item>
                  <v-card class="mt-3">
                    <v-text-field
                      outlined
                      dense
                      label="نام و نام‌خانوادگی"
                      disabled
                      v-model="patient.name"
                    ></v-text-field>
                    <v-select
                      v-model="patient.gender"
                      :items="genders"
                      item-text="text"
                      item-value="value"
                      label="جنسیت"
                      outlined
                      dense
                      disabled
                    ></v-select>
                    <v-select
                      v-model="patient.insurType"
                      :items="insurTypes"
                      item-text="text"
                      item-value="value"
                      label="نوع بیمه"
                      outlined
                      dense
                      disabled
                    ></v-select>
                    <v-text-field
                      v-model="patient.insurNo"
                      label="شماره بیمه"
                      outlined
                      dense
                      type="number"
                      disabled
                    ></v-text-field>
                    <v-text-field
                      v-model="patient.mobile"
                      type="number"
                      label="شماره موبایل"
                      outlined
                      dense
                      disabled
                    ></v-text-field>
                  </v-card>
                </v-tab-item>
                <!-- تاریخچه درمانی -->
                <v-tab-item>
                  <v-card>
                    <v-card-text class="ps-0 pe-0">
                      <v-row>
                        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                          <div class="patient-div">
                            <h6 class="primary--text">بیماری‌ها</h6>
                            <v-textarea
                              readonly
                              outlined
                              v-model="history.diseases"
                              rows="8"
                            ></v-textarea>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                          <div class="patient-div">
                            <h6 class="primary--text">حساسیت‌ها</h6>
                            <v-textarea
                              readonly
                              outlined
                              v-model="history.allergies"
                              rows="8"
                            ></v-textarea>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                          <div class="patient-div">
                            <h6 class="primary--text">
                              داروهای در حال استفاده
                            </h6>
                            <v-textarea
                              readonly
                              outlined
                              v-model="history.drugs"
                              rows="8"
                            ></v-textarea>
                          </div>
                        </v-col>
                      </v-row>
                      <br />
                      <br />

                      <h5>
                        جهت تغییر اطلاعات تاریخچه درمانی بیمار، از طریق پروفایل
                        بیمار اقدام کنید.
                      </h5>
                      <!--<v-expansion-panels accordion>
                        <v-expansion-panel>
                          <v-expansion-panel-header
                            >بیماری‌ها</v-expansion-panel-header
                          >
                          <v-expansion-panel-content class="my-3">
                            <v-textarea
                              outlined
                              disabled
                              v-model="history.diseases"
                              rows="8"
                            ></v-textarea>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header
                            >حساسیت‌ها</v-expansion-panel-header
                          >
                          <v-expansion-panel-content class="my-3">
                            <v-textarea
                              outlined
                              disabled
                              v-model="history.allergies"
                              rows="8"
                            ></v-textarea>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header
                            >داروهای در حال استفاده</v-expansion-panel-header
                          >
                          <v-expansion-panel-content class="my-3">
                            <v-textarea
                              outlined
                              disabled
                              v-model="history.drugs"
                              rows="8"
                            ></v-textarea>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>-->
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <!-- جزییات درخواست -->
                <v-tab-item>
                  <v-card class="pa-3">
                    <v-row v-if="role == 'admin'">
                      <v-col>
                        <h5
                          class="primary--text ms-1 me-1 mb-2 font-weight-bold"
                          style="display: inline-block"
                        >
                          وضعیت:
                        </h5>
                        <h5
                          class="ms-2 me-1 mb-2 grey--text text--darken-1"
                          style="display: inline-block"
                        >
                          {{ request.status }}
                        </h5></v-col
                      >
                      <v-col>
                        <v-btn
                          class="primary-btn ms-2"
                          @click="
                            isMounted = true;
                            printDetails();
                          "
                          style="float: left"
                          v-if="role == 'admin'"
                        >
                          <v-icon left>mdi-printer</v-icon>
                          <h6 class="mt-1">پرینت</h6>
                        </v-btn></v-col
                      >
                    </v-row>
                    <div>
                      <v-row>
                        <v-col cols="12" md="6">
                          <h5
                            class="primary--text ms-1 me-1 mb-2 font-weight-bold"
                            style="display: inline-block"
                          >
                            شماره نوبت:
                          </h5>
                          <h5
                            class="ms-2 me-1 mb-2 grey--text text--darken-1"
                            style="display: inline-block"
                          >
                            {{ request.no ? request.no : "-" }}
                          </h5></v-col
                        >
                        <v-col cols="12" md="6">
                          <h5
                            class="primary--text mb-2 font-weight-bold"
                            style="display: inline-block"
                          >
                            تاریخ ثبت:
                          </h5>
                          <h5
                            class="ms-2 me-1 mb-2 grey--text text--darken-1"
                            style="display: inline-block"
                          >
                            {{ request.date }}
                          </h5></v-col
                        >
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6">
                          <h5
                            class="primary--text ms-1 me-1 mb-2 font-weight-bold"
                            style="display: inline-block"
                          >
                            قیمت:
                          </h5>
                          <h5
                            class="ms-2 me-1 grey--text text--darken-1"
                            style="display: inline-block"
                          >
                            {{ Number(request.cost).toLocaleString() }} ریال
                          </h5>
                        </v-col>
                        <v-col cols="12" md="6">
                          <h5
                            class="primary--text ms-1 me-1 mb-2 font-weight-bold"
                            style="display: inline-block"
                          >
                            کارمند پذیرش:
                          </h5>
                          <h5
                            class="ms-2 me-1 grey--text text--darken-1"
                            style="display: inline-block"
                          >
                            {{
                              request.receptionName
                                ? request.receptionName
                                : "-"
                            }}
                          </h5></v-col
                        >
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6">
                          <h5
                            class="primary--text ms-1 me-1 mb-2 font-weight-bold"
                            style="display: inline-block"
                          >
                            {{
                              request.visitType == "dentist"
                                ? "دندانپزشک"
                                : "پزشک"
                            }}:
                          </h5>
                          <h5
                            class="ms-2 me-1 grey--text text--darken-1"
                            style="display: inline-block"
                          >
                            {{
                              request.doctorName
                                ? "دکتر " + request.doctorName
                                : "-"
                            }}
                          </h5></v-col
                        >
                        <v-col cols="12" md="6">
                          <h5
                            class="primary--text ms-1 me-1 mb-2 font-weight-bold"
                            style="display: inline-block"
                          >
                            پرستار:
                          </h5>
                          <h5
                            class="ms-2 me-1 grey--text text--darken-1"
                            style="display: inline-block"
                          >
                            {{ request.nurseName ? request.nurseName : "-" }}
                          </h5></v-col
                        >
                      </v-row>
                      <v-row v-if="request.isSubmittedByPatient">
                        <v-col>
                          <h5 class="grey--text text--darken-1">
                            <v-icon class="me-1 primary--text"
                              >mdi-alert-circle-check-outline</v-icon
                            >
                            این درخواست توسط بیمار به ثبت رسیده است.
                          </h5>
                        </v-col>
                      </v-row>
                      <div
                        v-if="
                          request.visitType == 'doctor' ||
                          request.visitType == 'dentist'
                        "
                        class="mt-1"
                      >
                        <v-row>
                          <v-col>
                            <div class="visit-div mt-4">
                              <h5
                                class="primary--text ms-1 me-1 mb-4 font-weight-bold"
                                style="display: inline-block"
                              >
                                شرح حال بیمار
                              </h5>
                              <p class="ms-1 me-1">
                                {{
                                  request.description
                                    ? request.description
                                    : "-"
                                }}
                              </p>
                            </div></v-col
                          >
                        </v-row>
                        <v-row>
                          <v-col>
                            <div class="visit-div mt-5">
                              <h5
                                class="primary--text ms-1 me-1 mb-4 font-weight-bold"
                                style="display: inline-block"
                              >
                                تشخیص
                                {{
                                  request.visitType == "dentist"
                                    ? "دندانپزشک"
                                    : "پزشک"
                                }}
                              </h5>
                              <p class="ms-1 me-1">
                                {{
                                  request.diagnosis ? request.diagnosis : "-"
                                }}
                              </p>
                            </div></v-col
                          >
                        </v-row>
                        <v-row>
                          <v-col>
                            <div class="visit-div mt-5">
                              <h5
                                class="primary--text ms-1 me-1 mb-4 font-weight-bold"
                                style="display: inline-block"
                              >
                                {{
                                  request.visitType == "dentist"
                                    ? "طرح درمان"
                                    : "تجویز پزشک"
                                }}
                              </h5>
                              <p class="ms-1 me-1">
                                {{
                                  request.ePrescription
                                    ? request.ePrescription
                                    : "-"
                                }}
                              </p>
                            </div></v-col
                          >
                        </v-row>
                        <v-row v-if="request.needOtherDoctor">
                          <v-col>
                            <div class="visit-div mt-5">
                              <h5
                                class="primary--text mt-4 ms-1 me-1 font-weight-bold"
                              >
                                بیمار نیاز به ویزیت مجدد توسط پزشک متخصص
                                {{ request.otherDoctorExpertise }}
                                دارد.
                              </h5>
                            </div></v-col
                          >
                        </v-row>
                      </div>
                      <div v-if="request.visitType == 'nurse'" class="mt-1">
                        <v-row>
                          <v-col>
                            <div class="visit-div mt-4">
                              <h5
                                class="primary--text ms-1 me-1 mb-4 font-weight-bold"
                                style="display: inline-block"
                              >
                                توضیحات بیماری
                              </h5>
                              <p class="ms-1 me-1">
                                {{ request.disease ? request.disease : "-" }}
                              </p>
                            </div></v-col
                          >
                        </v-row>
                        <v-row>
                          <v-col>
                            <div class="visit-div mt-4 text-center">
                              <h5
                                class="primary--text ms-1 me-1 mb-4 font-weight-bold"
                              >
                                توضیحات حساسیت دارویی
                              </h5>
                              <p class="ms-1 me-1">
                                {{
                                  request.isMedicalAllergy
                                    ? request.medicalAllergy
                                    : "حساسیت دارویی ندارد"
                                }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <div class="visit-div mt-4 text-center">
                              <h5
                                class="primary--text ms-1 me-1 mb-4 font-weight-bold"
                              >
                                خدمات پرستاری ارائه شده
                              </h5>
                              <p class="ms-1 me-1">
                                {{
                                  request.description
                                    ? request.description
                                    : "-"
                                }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                      <v-row v-if="role == 'admin'">
                        <v-col>
                          <div class="visit-div mt-8">
                            <h5
                              class="primary--text ms-1 me-1 mb-4 font-weight-bold"
                              style="display: inline-block"
                            >
                              یادداشت
                            </h5>
                            <p class="ms-1 me-1">
                              {{
                                request.note
                                  ? request.note
                                  : "یادداشتی برای این درخواست به ثبت نرسیده است."
                              }}
                            </p>
                          </div>
                        </v-col>
                      </v-row>
                    </div>

                    <v-row class="mt-12">
                      <v-col
                        cols="12"
                        sm="12"
                        :md="role == 'admin' ? '6' : '12'"
                        :lg="role == 'admin' ? '6' : '12'"
                        :xl="role == 'admin' ? '6' : '12'"
                      >
                        <div class="common-text common-div">
                          <strong>
                            <!-- inja -->
                            <v-icon color="primary">mdi-cash-multiple</v-icon>
                            مجموع قیمت :
                            {{ Number(request.cost).toLocaleString() }} ریال
                          </strong>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        lg="6"
                        xl="6"
                        v-if="role == 'admin'"
                      >
                        <div class="common-text common-div">
                          <strong>
                            <v-icon color="primary">mdi-cash-plus</v-icon>
                            دریافتی
                            {{
                              request.visitType == "dentist"
                                ? "دندانپزشک"
                                : "پزشک"
                            }}
                            :
                            {{ Number(request.docShare).toLocaleString() }} ریال
                          </strong>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card class="pa-3">
                    <b-table
                      responsive
                      show-empty
                      :fields="computedFields"
                      :items="itemSubServices"
                      empty-text="خدمتی برای نمایش وجود ندارد"
                      empty-filtered-text="خدمتی برای نمایش وجود ندارد"
                      :busy="BusySubServices"
                      :filter="FilterSubServices"
                      :current-page="CurrentPageSubServices"
                      :per-page="PerPageSubServices"
                      @filtered="onFiltered"
                    >
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.value }}
                        </div>
                      </template>

                      <template v-slot:cell(index)="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{
                            data.index +
                            PerPageSubServices * (CurrentPageSubServices - 1) +
                            1
                          }}
                        </div>
                      </template>
                      <template v-slot:cell(cost)="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ Number(data.value).toLocaleString() }}
                        </div>
                      </template>
                      <div
                        slot="table-busy"
                        class="text-center primary--text my-2"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          class="align-middle"
                        ></v-progress-circular>
                      </div>
                    </b-table>

                    <v-pagination
                      v-model="CurrentPageSubServices"
                      :length="
                        Math.ceil(TotalRowsSubServices / PerPageSubServices)
                      "
                      :total-visible="5"
                      prev-icon="arrow_back"
                      next-icon="arrow_forward"
                      style="float: center"
                    ></v-pagination>
                  </v-card>
                  <!-- receipt -->
                  <v-card
                    class="pa-2"
                    v-show="false"
                    style="float: left"
                    id="Receipt"
                  >
                    <div
                      class="container mb-0 pa-3"
                      style="border: 2px solid black"
                    >
                      <div
                        style="
                          text-align: center;
                          margin: auto;
                          font-size: 15px;
                          border-bottom: 4px solid black;
                        "
                      >
                        <img
                          src="/receiptLogo.png"
                          width="100"
                          height="100"
                          alt=""
                        />
                        <br />
                        درمانگاه شبانه روزی پارسیان البرز
                      </div>

                      <div
                        style="
                          direction: rtl;
                          font-weight: 900;
                          padding: 20px;
                          padding-bottom: 0;
                        "
                      >
                        شماره نوبت:
                        <span style="font-size = 16px">{{
                          request.no ? request.no : "-"
                        }}</span>
                        <br />{{ " وضعیت: " + request.status }}
                        <br />
                        {{ "نام بیمار: " + patient.name }}
                        <br />
                        {{ " کارمند پذیرش: " + request.receptionName }}
                        <br />
                        {{
                          (request.visitType == "dentist"
                            ? "دندانپزشک:"
                            : "پزشک:") + request.doctorName
                        }}
                        <br />
                        {{ " پرستار: " + request.nurseName }}
                        <br />
                        {{
                          "قیمت: " +
                          Number(request.cost).toLocaleString() +
                          "ریال"
                        }}
                        <br />
                        {{
                          (request.visitType == "dentist"
                            ? "دریافتی دندانپزشک:"
                            : "دریافتی پزشک:") +
                          Number(request.docShare).toLocaleString() +
                          "ریال"
                        }}
                        <br />
                        {{ " تاریخ ثبت: " + request.date }}
                      </div>
                      <div
                        v-if="
                          request.visitType == 'doctor' ||
                          request.visitType == 'dentist'
                        "
                        style="
                          padding: 20px;
                          padding-top: 10px;
                          direction: rtl;
                          font-weight: 900;
                          text-align: justify;
                        "
                      >
                        شرح حال بیمار:
                        {{ request.description ? request.description : "-" }}
                        <br />
                        <br />

                        تشخیص
                        {{
                          request.visitType == "dentist"
                            ? "دندانپزشک:"
                            : "پزشک:"
                        }}:
                        {{ request.diagnosis ? request.diagnosis : "-" }}
                        <br />
                        <br />
                        {{
                          request.visitType == "dentist"
                            ? "طرح درمان:"
                            : "تجویز پزشک:"
                        }}
                        {{
                          request.ePrescription ? request.ePrescription : "-"
                        }}
                      </div>
                      <div
                        v-if="request.visitType == 'nurse'"
                        style="
                          padding: 20px;
                          padding-top: 10px;
                          direction: rtl;
                          font-weight: 900;
                          text-align: justify;
                        "
                      >
                        توضیحات بیماری:
                        {{ request.disease }}
                        <br />
                        <br />
                        توضیحات حساسیت دارویی:
                        {{
                          request.isMedicalAllergy
                            ? request.medicalAllergy
                            : "حساسیت دارویی ندارد"
                        }}
                        <br />
                        <br />
                        خدمات پرستاری ارائه شده :
                        {{ request.description ? request.description : "-" }}
                      </div>
                      <div
                        style="
                          padding: 20px;
                          padding-top: 0;
                          direction: rtl;
                          font-weight: 900;
                          text-align: justify;
                        "
                      >
                        یادداشت:
                        {{ request.note ? request.note : "-" }}
                      </div>
                      <b-table
                        bordered
                        :fields="computedFields"
                        :items="itemSubServices"
                        small="small"
                        responsive
                        style="padding: 10px"
                      >
                        <template v-slot:head()="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.label }}
                          </div>
                        </template>
                        <template v-slot:cell(name)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.value }}
                          </div>
                        </template>
                        <template v-slot:cell(cost)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ Number(data.value).toLocaleString() }}
                          </div>
                        </template>
                      </b-table>
                      <br />
                      <div
                        v-if="request.needOtherDoctor"
                        style="padding-right: 20px; font-weight: 900"
                      >
                        بیمار نیاز به ویزیت مجدد توسط پزشک متخصص
                        {{ request.otherDoctorExpertise }}
                        دارد.
                      </div>
                      <br />
                    </div>
                    <br />
                    <!-- <barcode value="http://my.parsianalborz.com" displayValue="false" width="2" height="60">
        </barcode>   -->
                    <!--<h4 style="text-align: center">
                      http://my.parsianalborz.com
                    </h4>-->
                  </v-card>
                </v-tab-item>
                <!-- مدیریت درخواست -->
                <v-tab-item>
                  <v-card>
                    <v-row v-if="isDentistry">
                      <v-col>
                        <div :class="statusBadge">
                          <h6>{{ request.status }}</h6>
                        </div></v-col
                      >
                      <v-col>
                        <div
                          v-if="isDentistry && request.status != 'لغو'"
                          class="d-flex float-end"
                        >
                          <v-btn
                            class="red-btn ms-5"
                            @click="cancelRequest()"
                            :loading="cancelLoading"
                            >لغو درخواست</v-btn
                          >
                        </div></v-col
                      >
                    </v-row>
                    <div v-else-if="isDentistry == false">
                      <v-col class="pe-0 ps-0">
                        <v-select
                          label="وضعیت"
                          v-model="request.status"
                          :items="states"
                          item-text="text"
                          item-value="value"
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                      <hr />
                    </div>
                    <v-row class="mt-4 time-row">
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-autocomplete
                          v-if="!Busy"
                          v-model="request.docId"
                          :items="drNames"
                          item-text="text"
                          item-value="id"
                          label="نام پزشک"
                          outlined
                          dense
                          class="details-hidden"
                          :disabled="isDentistry"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-autocomplete
                          v-if="!Busy"
                          v-model="request.nurseId"
                          :items="isDentistry ? dentistNurseNames : nurseNames"
                          item-text="text"
                          item-value="id"
                          label="نام پرستار"
                          outlined
                          dense
                          class="details-hidden"
                          :disabled="isDentistry"
                        ></v-autocomplete>
                      </v-col>
                      <!--<v-col class="">
                        <v-btn
                          @click="addDetail()"
                          class=" mt-0 me-4 primary-btn"
                        >
                          <v-icon left>add</v-icon>
                          تعریف اقلام مصرفی
                        </v-btn>
                      </v-col>-->
                    </v-row>
                    <br />
                    <br />

                    <!--<hr />

                    <b-table
                      responsive
                      show-empty
                      :fields="Fields"
                      :items="Items"
                      empty-text="رکوردی برای نمایش وجود ندارد"
                      empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                    >
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.value }}
                        </div>
                      </template>
                      <template v-slot:cell(index)="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.index + 1 }}
                        </div>
                      </template>
                      <template v-slot:cell(operation)="data">
                        <div style="text-align: center; vertical-align: middle">
                          <v-btn
                            outlined
                            class="pa-2 mt-1 red-btn"
                            @click="deleteDetail(data.index)"
                            ><v-icon>remove</v-icon></v-btn
                          >
                        </div>
                      </template>

                      <template v-slot:cell(subject)="data">
                        <v-text-field
                          v-model="data.item.subject"
                          placeholder="عنوان"
                          class="pa-0"
                          outlined
                          dense
                        ></v-text-field>
                      </template>

                      <template v-slot:cell(expense)="data">
                        <div style="font-family: Tahoma">
                          <vuetify-money
                            v-model="data.item.description"
                            :valueWhenIsEmpty="whenIsEmpty"
                            :options="vMoneyOptions"
                            placeholder="هزینه"
                            type="number"
                            outlined
                            dense
                            style=""
                            class=""
                          />
                        </div>
                      </template>
                    </b-table> -->
                  </v-card>
                </v-tab-item>
                <!-- تغییر شیفت درخواست -->
                <v-tab-item v-if="role != 'reception'">
                  <v-card class="pa-2">
                    <div>
                      <v-row class="time-row">
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <span id="dateFrom3">
                            <v-text-field
                              outlined
                              dense
                              type="text"
                              append-icon="calendar_today"
                              v-model="dateFrom"
                              label=" تاریخ از "
                              :editable="true"
                              class="date-input"
                            >
                            </v-text-field>
                          </span>

                          <date-picker
                            v-model="dateFrom"
                            element="dateFrom3"
                            color="#00a7b7"
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <span id="dateTo3">
                            <v-text-field
                              outlined
                              dense
                              type="text"
                              append-icon="calendar_today"
                              v-model="dateTo"
                              label=" تاریخ تا "
                              :editable="true"
                              class="date-input"
                            >
                            </v-text-field>
                          </span>

                          <date-picker
                            v-model="dateTo"
                            element="dateTo3"
                            color="#00a7b7"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="time-row">
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <span id="timeFrom">
                            <v-text-field
                              outlined
                              dense
                              type="text"
                              append-icon="schedule"
                              v-model="timeFrom"
                              label=" ساعت از "
                              :editable="true"
                              class="date-input"
                            >
                            </v-text-field>
                          </span>

                          <date-picker
                            v-model="timeFrom"
                            element="timeFrom"
                            color="#00a7b7"
                            type="time"
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <span id="timeTo">
                            <v-text-field
                              outlined
                              dense
                              type="text"
                              append-icon="schedule"
                              v-model="timeTo"
                              label=" ساعت تا "
                              :editable="true"
                              class="date-input"
                            >
                            </v-text-field>
                          </span>

                          <date-picker
                            v-model="timeTo"
                            element="timeTo"
                            color="#00a7b7"
                            type="time"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-btn
                            class="primary-btn submit-btn"
                            style="float: right"
                            @click="getShifts()"
                            :disabled="Busy"
                            >اعمال</v-btn
                          >
                        </v-col>
                      </v-row>
                      <hr />
                      <b-table
                        responsive
                        show-empty
                        :fields="shiftsFields"
                        :items="shifts"
                        :busy="tableBusy"
                        empty-text="در بازه زمانی انتخاب شده شیفتی برای نمایش وجود ندارد"
                        empty-filtered-text="در بازه زمانی انتخاب شده شیفتی برای نمایش وجود ندارد"
                        :current-page="shiftsCurrentPage"
                        :per-page="shiftsPerPage"
                        @filtered="shiftonFiltered"
                      >
                        <template v-slot:head()="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.label }}
                          </div>
                        </template>
                        <template v-slot:cell()="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.value }}
                          </div>
                        </template>
                        <template v-slot:cell(index)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            {{ data.index + 1 }}
                          </div>
                        </template>
                        <template v-slot:cell(operation)="data">
                          <div
                            style="text-align: center; vertical-align: middle"
                          >
                            <v-btn
                              class="ms-2 me-2 primary-btn pa-2"
                              v-if="data.item.id != selectedShift"
                              :disabled="busy"
                              @click="selectedShift = data.item.id"
                              >انتخاب</v-btn
                            >
                            <v-btn
                              v-else
                              class="pa-2 red-btn"
                              @click="selectedShift = ''"
                              >حذف انتخاب</v-btn
                            >
                          </div>
                        </template>
                        <div
                          slot="table-busy"
                          class="text-center primary--text my-2"
                        >
                          <v-progress-circular
                            indeterminate
                            color="primary"
                            class="align-middle"
                          ></v-progress-circular>
                        </div>
                      </b-table>
                      <v-pagination
                        v-model="shiftsCurrentPage"
                        :length="Math.ceil(shiftsTotalRows / shiftsPerPage)"
                        :total-visible="5"
                        prev-icon="arrow_back"
                        next-icon="arrow_forward"
                        style="float: center"
                      ></v-pagination>
                    </div>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
            <v-btn
              class="primary-btn submit-btn"
              @click="editRequest()"
              v-if="isDentistry == false"
              >ثبت</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="deleteRequestDialog">
      <v-card>
        <v-card-title>
          <h3>حذف درخواست</h3>
        </v-card-title>
        <v-card-text>
          <br />
          <h4 style="line-height: 22px">
            <span class="mb-2">آیا از حذف این درخواست مطمئن هستید؟</span><br />
            <span>این کار غیر قابل برگشت خواهد بود!</span>
          </h4>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="red-btn" outlined @click="deleteRequestDialog = false">
            لغو
          </v-btn>
          <v-btn class="primary-btn" outlined @click="doDeleteDetail()">
            حذف
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import { mapGetters } from "vuex";
import moment from "moment-jalaali";

export default {
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      tab: null,
      deleteRequestDialog: false,
      patient: {},
      history: {},
      request: {},
      drName: "",
      nurseName: "",
      selectedDetail: "",
      states: [
        { value: "اتمام", text: "اتمام" },
        { value: "لغو", text: "لغو" },
        { value: "ثبت شده", text: "ثبت شده" },
        { value: "رزرو", text: "رزرو", disabled:true},
      ],
      genders: [
        { value: "زن", text: "زن" },
        { value: "مرد", text: "مرد" },
      ],

      CurrentPage: 1,
      PerPage: 10,
      TotalRows: "",

      Filter: "",
      Busy: false,
      busy: false,
      Fields: [
        { key: "index", label: "#" },
        { key: "subject", label: "عنوان درخواست " },
        { key: "expense", label: "هزینه (ریال)" },
        { key: "operation", label: "حذف" },
      ],
      Items: [],
      FilterSubServices: "",
      BusySubServices: false,
      fieldsSubServices: [
        { key: "index", label: "#" },
        { key: "name", label: "عنوان" },
        { key: "cost", label: "قیمت (ریال)" },
        {
          key: "protectiveExpense",
          label: "هزینه اقلام حفاظتی",
          requiresAdmin: true,
        },
        { key: "docPercent", label: "درصد سهم پزشک" },
      ],
      itemSubServices: [],
      CurrentPageSubServices: 1,
      PerPageSubServices: 10,
      TotalRowsSubServices: "",
      dateFrom: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      timeFrom: "00:00:00",
      timeTo: "23:59:59",
      tableBusy: false,
      shifts: [],
      shiftsCurrentPage: 1,
      shiftsPerPage: 10,
      shiftsTotalRows: "",
      shiftsFields: [
        { key: "index", label: "#" },
        { key: "shiftName", label: "عنوان شیفت" },
        { key: "startDate", label: "آغاز شیفت" },
        { key: "docName", label: "پزشک" },
        { key: "nurseName", label: "پرستار" },
        { key: "receptionName", label: "پذیرش" },
        { key: "operation", label: "عملیات" },
      ],
      selectedShift: "",
      role: "",
      docTotalPercent: "",
      isMounted: false,
      isDentistry: false,
      cancelLoading: false,
    };
  },

  methods: {
    addDetail() {
      this.Items.push({
        name: "",
        subject: "",
        description: "",
      });
    },
    // DELETING
    deleteDetail(index) {
      this.selectedDetail = index;
      this.deleteRequestDialog = true;
    },
    doDeleteDetail() {
      this.Items.splice(this.selectedDetail, 1);
      this.selectedDetail = "";
      this.deleteRequestDialog = false;
    },
    showReqInfo() {
      //NOTE getting all req info when page mount
      this.vLoading = true;
      if (this.isDentistry) {
        this.$http
          .post(
            this.baseUrl + "/clinic/dentistry/visits/id",
            {
              visitId: this.$route.params.id,
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              this.vLoading = false;
              this.patient = res.data.patient;
              this.history = res.data.history;
              this.request = res.data.visit;
              this.itemSubServices = res.data.visit.services
                ? JSON.parse(res.data.visit.services)
                : [];
              this.TotalRowsSubServices = this.itemSubServices.length;
              this.busySub = false;
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
              this.vLoading = false;
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.response.data, "error");
            this.vLoading = false;
          });
      } else {
        this.$http
          .post(
            this.baseUrl + "/clinic/visits/id",
            {
              visitId: this.$route.params.id,
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              this.vLoading = false;
              this.patient = res.data.patient;
              this.history = res.data.history;
              this.request = res.data.visit;
              this.itemSubServices = res.data.visit.services
                ? JSON.parse(res.data.visit.services)
                : [];

              this.TotalRowsSubServices = this.itemSubServices.length;
              this.busySub = false;
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
              this.vLoading = false;
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.response.data, "error");
            this.vLoading = false;
          });
      }
    },
    editRequest() {
      //NOTE request for editting
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/visits/edit",
          {
            visitId: this.$route.params.id,
            status: this.request.status,
            docId: this.request.docId,
            nurseId: this.request.nurseId,
            shiftId: this.selectedShift,
            description: JSON.stringify(this.Items),
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.vLoading = false;
            this.toast(res.data, "success");
            this.$router.push("/" + this.role + "/requests/");
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
        });
    },
    cancelRequest() {
      this.cancelLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/dentistry/visits/cancel",
          {
            visitId: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.cancelLoading = false;
            this.toast(res.data, "success");
            this.showReqInfo();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.cancelLoading = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.cancelLoading = false;
        });
    },
    getShifts() {
      this.tableBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/shift/filter",
          {
            startDate: this.dateFrom + this.timeFrom,
            endDate: this.dateTo + this.timeTo,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.tableBusy = false;
          if (res.status == 200) {
            this.shifts = res.data;
            this.shiftsTotalRows = this.shifts.length;
            this.shiftsCurrentPage = 1;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.tableBusy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
    shiftonFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.shiftsTotalRows = filteredItems.length;
      this.shiftsCurrentPage = 1;
    },
    printDetails() {
      this.print();
      this.isMounted = false;
    },
    print() {
      var myWindow = window.open("#", "Receipt", "height=auto,width=800");
      myWindow.document.write(
        "<html><head><link rel='stylesheet' href='/receipt.css'><title>Receipt</title>"
      );
      myWindow.document.write("</head><body>");
      myWindow.document.write(document.getElementById("Receipt").innerHTML);
      myWindow.document.write("</body></html>");
      myWindow.document.close();

      myWindow.onload = function () {
        myWindow.focus();
        setTimeout(() => {
          myWindow.print();
          myWindow.close();
        }, 500);
      };
    },
  },
  computed: {
    ...mapGetters(["drNames", "nurseNames", "insurTypes", "dentistNurseNames"]),
    computedFields() {
      // If the user isn't an admin
      if (this.role != "admin")
        return this.fieldsSubServices.filter((field) => !field.requiresAdmin);
      // If the user is an admin, return all fields.
      else return this.fieldsSubServices;
    },
    statusBadge() {
      if (this.request.status == "اتمام") {
        return "green-badge";
      } else if (this.request.status == "ثبت شده") {
        return "blue-badge";
      } else if (this.request.status == "رزرو") {
        return "orange-badge";
      } else {
        return "red-badge";
      }
    },
  },

  mounted() {
    this.role = localStorage.getItem("role");
    this.$route.query.type == "dentistry" ? (this.isDentistry = true) : "";
    this.showReqInfo();
  },
};
</script>
